import Vue from 'vue'

// axios
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'
import store from '@/store/index'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  //baseURL: 'http://localhost:8000/',
  baseURL: 'https://core-ag5.agentscout.it/',
  // timeout: 2000,
  // headers: {'X-Custom-Header': 'foobar'}
})

/*
// Add a request interceptor (prima dell'invio della chiamata)
axiosIns.interceptors.request.use(function (config) {
  // Do something before request is sent
  console.log("-*-*-*- Do something before request is sent ------")
  return config;
}, function (error) {
  // Do something with request error
  console.log("-*-*-*- Do something with request error ------")
  return Promise.reject(error);
});
*/

// Add a response interceptor (dopo la risposta dell'api)
axiosIns.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  //console.log("-*-*-*- Risposta da API ricevuta ------")
  //console.log(response)

  if(response.data.statusCode===401){
    //accesso "Unauthenticated" -> accade quando si sviene sloggati dall'api
    //console.error("Errore api -> "+response.data.reply['message'])

    if(logoutGeneralDirect() === true){
      // Redirect to login page
      //this.$router.push({ name: 'auth-login' })
      router.replace('/login?status=again')
    }
  } else {
    /* salva dati delle notifiche agli utenti */
    //console.log("NOTIFICHE -----------")
    //console.log(response.data.notice)

    if(response.data.notice !== undefined){
      store.commit('app/CHANGE_NOTIFICHE', response.data.notice)
    }
  }

  //bisogna sempre rispondere anche in caso di statusCode===401 altrimenti si generano errori nella console.log sulle rotte
  return response;
  
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log("-*-*-*- Errore in risposta da api -> Any status codes that falls outside the range of 2xx cause this function to trigger ------")
  return Promise.reject(error);
});

Vue.prototype.$http = axiosIns

export function logoutGeneralDirect() {
  // Remove userData from localStorage
  console.log("logoutGeneralDirect *****************")
  // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

  // Remove userData from localStorage
  localStorage.removeItem('userData')

  return true
}

export default axiosIns

